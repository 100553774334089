import fullpage from 'fullpage.js';

function homeFullPage() {

	const html = document.querySelector('html');
	const body = document.querySelector('body');
	const homePage = document.getElementById('home-page');

	if (!homePage) {

		return;

	}

	homePage.style.opacity = '0';

	const settings = {
		continuousVertical: true,
		touchSensitivity: 3,
		menu: '#dot-links',
		lockAnchors: false,
		onLeave: (origin) => {

			origin.item.classList.add('leaving');

		},
		afterLoad: (origin) => {

			origin.item.classList.remove('leaving');

		},
		afterRender: (e) => {

			html.classList.add('home');
			body.classList.add('home');

			setInterval(() => {

				homePage.style.opacity = '1';

			}, 800);

			setInterval(() => {

				fullpage_api.moveSectionDown();

			}, 6000);

		}
	}

	new fullpage(homePage, settings);

}

window.addEventListener('DOMContentLoaded', homeFullPage, false);
